/* eslint-disable camelcase */

/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
'use strict';

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import Url from './url';
const PaymentServer = {
  /**
   * Navigate to the payments server after flushing metrics.
   *
   * @param {Object} view
   * @param {Object} subscriptionsConfig
   * @param {String} redirectPath
   * @param {Object} [queryParams={}]
   */
  navigateToPaymentServer(view, subscriptionsConfig, redirectPath) {
    let queryParams = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    const managementClientId = subscriptionsConfig.managementClientId,
          managementScopes = subscriptionsConfig.managementScopes,
          managementTokenTTL = subscriptionsConfig.managementTokenTTL,
          managementUrl = subscriptionsConfig.managementUrl;

    const _view$metrics$getFlow = view.metrics.getFlowEventMetadata(),
          deviceId = _view$metrics$getFlow.deviceId,
          flowBeginTime = _view$metrics$getFlow.flowBeginTime,
          flowId = _view$metrics$getFlow.flowId;

    return view.getSignedInAccount().createOAuthToken(managementClientId, {
      scope: managementScopes,
      ttl: managementTokenTTL
    }).then(accessToken => {
      const queryString = Url.objToSearchString(_objectSpread({
        // device_id, flow_begin_time, and flow_id need to be propagated to
        // the payments server so that the user funnel can be traced from the RP,
        // through the content server, and to the payments server, appearing as
        // the same user throughout.
        device_id: deviceId,
        flow_begin_time: flowBeginTime,
        flow_id: flowId
      }, queryParams));
      const hashString = Url.objToHashString({
        accessToken: accessToken.get('token')
      });
      const url = `${managementUrl}/${redirectPath}${queryString}${hashString}`;
      view.navigateAway(url);
    });
  }

};
export default PaymentServer;