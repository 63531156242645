var _Vat$string;

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

/**
 * The Sync relier.
 */
import _ from 'underscore';
import AuthErrors from '../../lib/auth-errors';
import Constants from '../../lib/constants';
import CountryTelephoneInfo from '../../lib/country-telephone-info';
import Relier from './relier';
import Vat from '../../lib/vat';

const t = msg => msg;

const AllowedCountries = Object.keys(CountryTelephoneInfo);
/*eslint-disable camelcase*/

const QUERY_PARAMETER_SCHEMA = {
  // context is not available when verifying.
  context: Vat.string().min(1),
  country: (_Vat$string = Vat.string()).valid.apply(_Vat$string, _toConsumableArray(AllowedCountries)),
  service: Vat.string(),
  // signin code, from an SMS. Note, this is *not* validated
  // because users that open the verification link with an
  // invalid signinCode should still be able to sign in.
  // The error will be logged and the signinCode ignored.
  signin: Vat.string().empty('').renameTo('signinCode')
};
/*eslint-enable camelcase*/

export default Relier.extend({
  defaults: _.extend({}, Relier.prototype.defaults, {
    action: undefined,
    name: 'browser',

    /*
    syncPreference specifies the user's choice for the following:
      undefined - unset, happens during force_auth, sync setting in Firefox should not change
      true - user wants to turn on sync
      false - user doesn't want sync
     */
    syncPreference: undefined,
    multiService: false,
    signinCode: undefined,
    tokenCode: true
  }),

  initialize(attributes) {
    let options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    this._translator = options.translator;
    Relier.prototype.initialize.call(this, attributes, options);
  },

  fetch() {
    return Relier.prototype.fetch.call(this).then(() => {
      this.importSearchParamsUsingSchema(QUERY_PARAMETER_SCHEMA, AuthErrors); // Customize the serviceName based on *why* the user is signing in.

      if (this.get('service') === 'sync') {
        this.set('serviceName', t(Constants.RELIER_SYNC_SERVICE_NAME));
      } else {
        this.set('serviceName', t('Firefox'));
      }
    });
  },

  isSync() {
    return true;
  },

  shouldOfferToSync(viewName) {
    return this.get('service') !== 'sync' && viewName !== 'force-auth';
  },

  /**
   * Desktop clients will always want keys so they can sync.
   *
   * @returns {Boolean}
   */
  wantsKeys() {
    return true;
  }

});