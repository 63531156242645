/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
/**
 * List of valid `prompt` query parameters
 */
var OAuthPrompt;
(function (OAuthPrompt) {
    OAuthPrompt["CONSENT"] = "consent";
    OAuthPrompt["NONE"] = "none";
})(OAuthPrompt || (OAuthPrompt = {}));
export default OAuthPrompt;
