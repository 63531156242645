function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }

/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

/**
 * A dropin replacement for Cocktail that ensure any of a mixin's
 * dependencies are also mixed in.
 *
 * A mixin that declares `dependsOn` will have all dependencies
 * mixed in beforehand.
 *
 * NOTE: This does not check for circular dependencies. We depend on
 * the fact that mixins are in their own module and require might complain
 * about circular dependencies.
 */
import Cocktail from 'cocktail-lib';
import _ from 'underscore';

function mixin(target) {
  for (var _len = arguments.length, mixins = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
    mixins[_key - 1] = arguments[_key];
  }

  Cocktail.mixin.apply(Cocktail, [target].concat(_toConsumableArray(getAllMixins(mixins))));
}

function getAllMixins(mixins) {
  // The approach - generate a big list, possibly with duplicates.
  // Deduplicate as a final step.
  return _.uniq(mixins.reduce((accumulator, mixin) => {
    if (mixin.dependsOn) {
      accumulator.push.apply(accumulator, _toConsumableArray(getAllMixins(mixin.dependsOn)));
    }

    accumulator.push(mixin);
    return accumulator;
  }, []));
}
/**
 * Check if `mixin` is mixed into `target` by checking
 * whether `target` contains all of the properties/methods
 * of `mixin`. Not foolproof, if `mixin` contains only
 * properties and `target` already has all the properties,
 * then `true` will be returned even if `target`s properties
 * are not the same as `mixin`s properties.
 *
 * @param {Object} target
 * @param {Object} mixin
 * @returns {Boolean}
 */


function isMixedIn(target, mixin) {
  return _.every(Object.keys(mixin), key => key in target);
}

export default {
  isMixedIn,
  mixin
};