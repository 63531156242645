import "core-js/modules/es.symbol.description";
import "core-js/modules/es.symbol.async-iterator";
import "core-js/modules/es.symbol.match";
import "core-js/modules/es.symbol.replace";
import "core-js/modules/es.symbol.search";
import "core-js/modules/es.symbol.split";
import "core-js/modules/es.array.flat";
import "core-js/modules/es.array.flat-map";
import "core-js/modules/es.array.unscopables.flat";
import "core-js/modules/es.array.unscopables.flat-map";
import "core-js/modules/es.number.to-fixed";
import "core-js/modules/es.object.assign";
import "core-js/modules/es.object.from-entries";
import "core-js/modules/es.promise";
import "core-js/modules/es.promise.finally";
import "core-js/modules/es.reflect.set";
import "core-js/modules/es.regexp.constructor";
import "core-js/modules/es.regexp.flags";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.ends-with";
import "core-js/modules/es.string.includes";
import "core-js/modules/es.string.match";
import "core-js/modules/es.string.replace";
import "core-js/modules/es.string.search";
import "core-js/modules/es.string.split";
import "core-js/modules/es.string.starts-with";
import "core-js/modules/es.string.trim-end";
import "core-js/modules/es.string.trim-start";
import "core-js/modules/es.typed-array.to-locale-string";
import "core-js/modules/esnext.aggregate-error";
import "core-js/modules/esnext.array.last-index";
import "core-js/modules/esnext.array.last-item";
import "core-js/modules/esnext.composite-key";
import "core-js/modules/esnext.composite-symbol";
import "core-js/modules/esnext.global-this";
import "core-js/modules/esnext.map.delete-all";
import "core-js/modules/esnext.map.every";
import "core-js/modules/esnext.map.filter";
import "core-js/modules/esnext.map.find";
import "core-js/modules/esnext.map.find-key";
import "core-js/modules/esnext.map.from";
import "core-js/modules/esnext.map.group-by";
import "core-js/modules/esnext.map.includes";
import "core-js/modules/esnext.map.key-by";
import "core-js/modules/esnext.map.key-of";
import "core-js/modules/esnext.map.map-keys";
import "core-js/modules/esnext.map.map-values";
import "core-js/modules/esnext.map.merge";
import "core-js/modules/esnext.map.of";
import "core-js/modules/esnext.map.reduce";
import "core-js/modules/esnext.map.some";
import "core-js/modules/esnext.map.update";
import "core-js/modules/esnext.math.clamp";
import "core-js/modules/esnext.math.deg-per-rad";
import "core-js/modules/esnext.math.degrees";
import "core-js/modules/esnext.math.fscale";
import "core-js/modules/esnext.math.iaddh";
import "core-js/modules/esnext.math.imulh";
import "core-js/modules/esnext.math.isubh";
import "core-js/modules/esnext.math.rad-per-deg";
import "core-js/modules/esnext.math.radians";
import "core-js/modules/esnext.math.scale";
import "core-js/modules/esnext.math.seeded-prng";
import "core-js/modules/esnext.math.signbit";
import "core-js/modules/esnext.math.umulh";
import "core-js/modules/esnext.number.from-string";
import "core-js/modules/esnext.observable";
import "core-js/modules/esnext.promise.all-settled";
import "core-js/modules/esnext.promise.any";
import "core-js/modules/esnext.promise.try";
import "core-js/modules/esnext.reflect.define-metadata";
import "core-js/modules/esnext.reflect.delete-metadata";
import "core-js/modules/esnext.reflect.get-metadata";
import "core-js/modules/esnext.reflect.get-metadata-keys";
import "core-js/modules/esnext.reflect.get-own-metadata";
import "core-js/modules/esnext.reflect.get-own-metadata-keys";
import "core-js/modules/esnext.reflect.has-metadata";
import "core-js/modules/esnext.reflect.has-own-metadata";
import "core-js/modules/esnext.reflect.metadata";
import "core-js/modules/esnext.set.add-all";
import "core-js/modules/esnext.set.delete-all";
import "core-js/modules/esnext.set.difference";
import "core-js/modules/esnext.set.every";
import "core-js/modules/esnext.set.filter";
import "core-js/modules/esnext.set.find";
import "core-js/modules/esnext.set.from";
import "core-js/modules/esnext.set.intersection";
import "core-js/modules/esnext.set.is-disjoint-from";
import "core-js/modules/esnext.set.is-subset-of";
import "core-js/modules/esnext.set.is-superset-of";
import "core-js/modules/esnext.set.join";
import "core-js/modules/esnext.set.map";
import "core-js/modules/esnext.set.of";
import "core-js/modules/esnext.set.reduce";
import "core-js/modules/esnext.set.some";
import "core-js/modules/esnext.set.symmetric-difference";
import "core-js/modules/esnext.set.union";
import "core-js/modules/esnext.string.at";
import "core-js/modules/esnext.string.code-points";
import "core-js/modules/esnext.string.match-all";
import "core-js/modules/esnext.string.replace-all";
import "core-js/modules/esnext.symbol.dispose";
import "core-js/modules/esnext.symbol.observable";
import "core-js/modules/esnext.symbol.pattern-match";
import "core-js/modules/esnext.weak-map.delete-all";
import "core-js/modules/esnext.weak-map.from";
import "core-js/modules/esnext.weak-map.of";
import "core-js/modules/esnext.weak-set.add-all";
import "core-js/modules/esnext.weak-set.delete-all";
import "core-js/modules/esnext.weak-set.from";
import "core-js/modules/esnext.weak-set.of";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.immediate";
import "core-js/modules/web.queue-microtask";
import "core-js/modules/web.url";
import "core-js/modules/web.url.to-json";
import "core-js/modules/web.url-search-params";

/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
import ConfigLoader from './lib/config-loader';
import AppStart from './lib/app-start';
const configLoader = new ConfigLoader();
configLoader.fetch().then(config => {
  const appStart = new AppStart({
    config
  });
  configLoader.useConfig(config);
  appStart.startApp();
});