/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
/**
 * List of methods by which a user can verify.
 * A user can currently only verify via email.
 * In the future SMS and TOTP could also be included.
 */
var VerificationMethods;
(function (VerificationMethods) {
    VerificationMethods["EMAIL"] = "email";
    VerificationMethods["EMAIL_2FA"] = "email-2fa";
    VerificationMethods["EMAIL_CAPTCHA"] = "email-captcha";
    VerificationMethods["EMAIL_OTP"] = "email-otp";
    VerificationMethods["TOTP_2FA"] = "totp-2fa";
})(VerificationMethods || (VerificationMethods = {}));
export default VerificationMethods;
