/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
export default {
  match($el) {
    return $el.attr('type') === 'checkbox';
  },

  val(val) {
    return !!this.is(':checked');
  },

  validate() {// always valid
  }

};