/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */
/**
 * List of reasons a user must perform some form of verification.
 */
var VerificationReasons;
(function (VerificationReasons) {
    VerificationReasons["CHANGE_PASSWORD"] = "change_password";
    VerificationReasons["ECOSYSTEM_ANON_ID"] = "anon_id";
    VerificationReasons["FORCE_AUTH"] = "force_auth";
    VerificationReasons["PASSWORD_RESET"] = "reset_password";
    VerificationReasons["PASSWORD_RESET_WITH_RECOVERY_KEY"] = "reset_password_with_recovery_key";
    VerificationReasons["PRIMARY_EMAIL_VERIFIED"] = "primary_email_verified";
    VerificationReasons["RECOVERY_KEY"] = "recovery_key";
    VerificationReasons["SECONDARY_EMAIL_VERIFIED"] = "secondary_email_verified";
    VerificationReasons["SIGN_IN"] = "login";
    VerificationReasons["SIGN_UP"] = "signup";
    VerificationReasons["SUCCESSFUL_OAUTH"] = "oauth_success";
})(VerificationReasons || (VerificationReasons = {}));
export default VerificationReasons;
