// The ProductDetails type is exploded out into enums describing keys to
// make Stripe metadata parsing & validation easier.
export var ProductDetailsStringProperties;
(function (ProductDetailsStringProperties) {
    ProductDetailsStringProperties[ProductDetailsStringProperties["subtitle"] = 0] = "subtitle";
    ProductDetailsStringProperties[ProductDetailsStringProperties["termsOfServiceURL"] = 1] = "termsOfServiceURL";
    ProductDetailsStringProperties[ProductDetailsStringProperties["termsOfServiceDownloadURL"] = 2] = "termsOfServiceDownloadURL";
    ProductDetailsStringProperties[ProductDetailsStringProperties["privacyNoticeURL"] = 3] = "privacyNoticeURL";
    ProductDetailsStringProperties[ProductDetailsStringProperties["privacyNoticeDownloadURL"] = 4] = "privacyNoticeDownloadURL";
})(ProductDetailsStringProperties || (ProductDetailsStringProperties = {}));
export var ProductDetailsListProperties;
(function (ProductDetailsListProperties) {
    ProductDetailsListProperties[ProductDetailsListProperties["details"] = 0] = "details";
})(ProductDetailsListProperties || (ProductDetailsListProperties = {}));
